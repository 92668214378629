import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { sendNewsletter } from '../../utils/mmc-api/api';

function FileUpload({ onFileInput }) {
  const inputRef = React.useRef(null);

  const handleFileSelection = (e) => {
    const files = e.currentTarget.files;
    if (files) onFileInput(files[0]);
  };

  return (
    <>
      <input
        type="file"
        ref={inputRef}
        onChange={(e) => handleFileSelection(e)}
        style={{ display: 'none' }}
      />

      <div className="file-input-container">
        <button className="button ml-0 mb-0.5" onClick={() => inputRef.current.click()}>
          Select file
        </button>
      </div>
    </>
  );
}

const getBase64 = (file) => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = '';
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export default function NewsletterView() {
  const [subject, setSubject] = React.useState('');
  const [sending, setSending] = React.useState(false);
  const [attachments, setAttachments] = React.useState([]);
  const editorRef = useRef(null);

  const handleSubmit = async () => {
    if (sending) return;
    setSending(true);
    const body = {
      subject,
      html: editorRef.current.getContent(),
      files: [],
    };

    for (const attachment of attachments) {
      const base64Version = await getBase64(attachment);
      body.files.push({ base64: base64Version, fileName: attachment.name, type: attachment.type });
    }

    await sendNewsletter(body);
    setSending(false);
  };

  return (
    <div className="container">
      <label htmlFor="subject">Subject</label>
      <input
        type="text"
        id="subject"
        className="input"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <Editor
        apiKey={process.env.GATSBY_TINYMCE_API_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue="<p>This is the initial content of the editor.</p>"
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'print',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'paste',
            'code',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | formatselect | h1 h2 h3 p | link | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
      <p>Attachments</p>
      <FileUpload
        onFileInput={(file) => {
          setAttachments((prev) => [...prev, file]);
        }}
      />
      <p>Selected Attachments:</p>
      {attachments.length ? (
        attachments.map((attachment, i) => {
          return (
            <div className="selected-file-container">
              <p>{attachment.name}</p>
              <button
                className="file-delete-button button"
                onClick={() => {
                  setAttachments((prev) => prev.filter((_, j) => j !== i));
                }}
              >
                Delete
              </button>
            </div>
          );
        })
      ) : (
        <p>No attachments selected</p>
      )}
      <button className="ml-0 mt-0.5 button" disabled={sending} onClick={handleSubmit}>
        {sending ? 'Sending...' : 'Send newsletter'}
      </button>
    </div>
  );
}
