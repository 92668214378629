import React from 'react';

import Spinner from '../Spinner';

const LoaderOverlay = () => {
	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				backgroundColor: 'rgba(255, 255, 255, 0.72)',
				position: 'absolute',
				zIndex: 999999,
			}}
		>
			<Spinner />
		</div>
	);
};

export default LoaderOverlay;
