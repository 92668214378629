import React from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { getSubmissions, markSubmission } from '../../utils/mmc-api/api';

const API_URL = process.env.GATSBY_MMC_API_URL; //Strapi instance

const HEADERS = [
  { label: 'Question number', value: 'challenge_question' },
  { label: 'User', value: 'username' },
  { label: 'Correct?', value: 'correct' },
  { label: 'Solution', value: 'url' },
  { label: 'Submission time', value: 'submission_time' },
  { label: 'Action', value: 'action' },
];

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'full',
  timeStyle: 'medium',
});

function Check({ size = '1rem' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      style={{
        width: size,
        height: size,
      }}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
    </svg>
  );
}

function X({ size = '1rem' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      style={{
        width: size,
        height: size,
      }}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>
  );
}

export default function ChallengeQuestions() {
  const { data, loading } = useQuery(['challenge-questions-submissions'], getSubmissions);
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useMutation({
    mutationFn: ({ id, correct }) => markSubmission({ id, correct }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['challenge-questions-submissions'] });
    },
  });

  const questionNumbers = data
    ? ['All', ...new Set(data.map((response) => response.challenge_question))]
    : [];

  const [selectedQuestionNumber, setSelectedQuestionNumber] = React.useState('All');
  const [selectedMarkedState, setSelectedMarkedState] = React.useState('All');

  const markedOptions = ['All', 'Marked', 'Unmarked'];

  const filteredData =
    data?.filter((response) => {
      if (selectedQuestionNumber === 'All' && selectedMarkedState === 'All') return true;
      if (selectedQuestionNumber === 'All') {
        if (selectedMarkedState === 'Marked') return response.correct !== null;
        if (selectedMarkedState === 'Unmarked') return response.correct === null;
      } else if (selectedMarkedState === 'All') {
        return response.challenge_question === parseInt(selectedQuestionNumber);
      } else {
        if (selectedMarkedState === 'Marked')
          return (
            response.correct !== null &&
            response.challenge_question === parseInt(selectedQuestionNumber)
          );
        if (selectedMarkedState === 'Unmarked')
          return (
            response.correct === null &&
            response.challenge_question === parseInt(selectedQuestionNumber)
          );
      }
    }) ?? [];

  const updateFilter = (type, value) => {
    if (type === 'questionNumber') {
      setSelectedQuestionNumber(value);
    } else if (type === 'markedState') {
      setSelectedMarkedState(value);
    }
  };

  if (loading)
    return (
      <div>
        <p>Loading</p>
      </div>
    );

  const renderRow = (rowData, index) => {
    return (
      <tr>{HEADERS.map((header) => renderCell(header.value, rowData[header.value], index))}</tr>
    );
  };

  const markUserSubmission = (index, correct) => {
    mutate({ id: data[index].id, correct: correct });
  };

  const renderCell = (header, value, index) => {
    if (header === 'submission_time') {
      return <td className="text-centered">{dateFormatter.format(new Date(value))}</td>;
    }
    if (header === 'action') {
      return (
        <td>
          <div className="flex x-around">
            <div className="cursor-pointer" onClick={() => markUserSubmission(index, true)}>
              <Check size="2rem" />
            </div>
            <div className="cursor-pointer" onClick={() => markUserSubmission(index, false)}>
              <X size="2rem" />
            </div>
          </div>
        </td>
      );
    }
    if (header === 'correct') {
      return (
        <td className="text-centered">
          {value === null ? 'No yet marked' : value ? 'Correct' : 'Incorrect'}
        </td>
      );
    }
    if (header === 'url') {
      return (
        <td className="text-centered">
          <button
            className="button"
            onClick={() => {
              window.open(`${API_URL}${value}`, '_target');
            }}
          >
            View solution
          </button>
        </td>
      );
    }
    return <td className="text-centered">{value}</td>;
  };

  return (
    <div className="container">
      <div className="srow">
        <div className="scolumn narrow mr-0.5">
          <label htmlFor="question-number" style={{ marginBottom: 0, marginRight: '2px' }}>
            Question number
          </label>
          <select
            name="question-number"
            id="question-number"
            className="dropdown"
            value={selectedQuestionNumber}
            onChange={(e) => updateFilter('questionNumber', e.target.value)}
          >
            {questionNumbers.map((num) => (
              <option value={num}>{num}</option>
            ))}
          </select>
        </div>
        <div className="scolumn narrow">
          <label htmlFor="marked-state" style={{ marginBottom: 0, marginRight: '2px' }}>
            Marked state
          </label>

          <select
            name="marked-state"
            id="marked-state"
            className="dropdown"
            value={selectedMarkedState}
            onChange={(e) => updateFilter('markedState', e.target.value)}
          >
            {markedOptions.map((state) => (
              <option value={state}>{state}</option>
            ))}
          </select>
        </div>
      </div>
      <table className="table topic-table">
        <thead>
          <tr
            style={{
              textAlign: 'center',
              backgroundColor: '#fd99c5',
              border: '1px solid black',
            }}
          >
            {HEADERS.map((header) => (
              <th className="p-1" style={{ textAlign: 'center', border: '1px solid black' }}>
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData?.length ? (
            filteredData?.map((row, i) => renderRow(row, i))
          ) : (
            <tr>
              <td colSpan={HEADERS.length} className="text-centered">
                No submissions yet
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
