import React from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { getChallengeQuestions, toggleQuestionOpen } from '../../utils/mmc-api/api';

import DocumentIcon from '../../assets/icons/pdf2.svg';
import WordDocumentIcon from '../../assets/icons/word-icon.png';
import YoutubeLogo from '../../assets/images/youtube_logo.png';
import CloseIcon from '../../assets/icons/close.svg';

const API_URL = process.env.GATSBY_MMC_API_URL; //Strapi instance

const HEADERS = [
  {
    label: 'Question number',
    value: 'id',
  },
  {
    label: 'Rating',
    value: 'rating',
  },

  {
    label: 'Question URL',
    value: 'question_url',
  },
  {
    label: 'Video solution',
    value: 'video_solution',
  },
  { label: 'Open?', value: 'open' },
  { label: 'Action', value: 'action' },
];

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'full',
  timeStyle: 'medium',
});

function Star({ size = '1rem', filled = true, color = 'black' }) {
  if (filled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        style={{
          width: size,
          height: size,
          color,
        }}
      >
        <path
          fillRule="evenodd"
          d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
          clipRule="evenodd"
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        style={{
          width: size,
          height: size,
          color,
        }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
        />
      </svg>
    );
  }
}

function Lock({ size = '1rem', open = false }) {
  if (open) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        style={{
          width: size,
          height: size,
        }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        style={{
          width: size,
          height: size,
        }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
        />
      </svg>
    );
  }
}

export default function ChallengeQuestions() {
  const { data, isLoading } = useQuery(['challenge-questions'], getChallengeQuestions);
  const queryClient = useQueryClient();

  const { mutate, isError } = useMutation({
    mutationFn: ({ id, open }) => toggleQuestionOpen({ id, open }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['challenge-questions'] });
    },
  });

  if (isLoading)
    return (
      <div>
        <p>Loading</p>
      </div>
    );
  const renderRow = (rowData, index, headers = HEADERS) => {
    return (
      <tr>{headers.map((header) => renderCell(header.value, rowData[header.value], index))}</tr>
    );
  };

  const renderCell = (header, value = '', index) => {
    if (header === 'action') {
      return (
        <td>
          <div className="flex x-around">
            <div
              className="cursor-pointer"
              onClick={() => {
                mutate({ id: data[index].id, open: !data[index].open });
              }}
            >
              <Lock open={!data[index].open} size="2rem" />
            </div>
          </div>
        </td>
      );
    }
    if (header === 'index') {
      return <td className="text-centered">{index + 1}</td>;
    }
    if (header === 'open') {
      return <td className="text-centered">Question is {value ? 'open' : 'closed'}</td>;
    }
    if (header === 'rating')
      return (
        <td className="text-centered">
          <div className="star-container">
            {[...Array(value)].map((_) => (
              <Star color="gold" />
            ))}
            {[...Array(5 - value)].map((_) => (
              <Star filled={false} />
            ))}
          </div>
        </td>
      );
    if (typeof value === 'string' && value?.includes('.pdf')) {
      return (
        <td className="text-centered">
          <img
            src={DocumentIcon}
            className="category-option-image doc-icon"
            alt="Category option"
            onClick={() => window.open(value, '_blank')}
          />
        </td>
      );
    }
    if (typeof value === 'string' && value?.includes('.docx')) {
      return (
        <td className="text-centered">
          <img
            src={WordDocumentIcon}
            className="category-option-image doc-icon"
            alt="Category option"
            onClick={() => window.open(value, '_blank')}
          />
        </td>
      );
    }
    if (typeof value === 'string' && value?.includes('youtube.com')) {
      return (
        <td className="text-centered">
          <img
            src={YoutubeLogo}
            onClick={() => window.open(value, '_blank')}
            className="category-option-image"
            alt="Category option"
          />
        </td>
      );
    }
    return <td className="text-centered">{value ?? 'Coming soon'}</td>;
  };

  return (
    <div className="container">
      <table className="table topic-table" id="questions">
        <thead>
          <tr
            style={{
              textAlign: 'center',
              backgroundColor: '#fd99c5',
              border: '1px solid black',
            }}
          >
            {HEADERS.map((header) => (
              <th className="p-1" style={{ textAlign: 'center', border: '1px solid black' }}>
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length ? (
            data.map((row, i) => renderRow(row, i))
          ) : (
            <tr>
              <td colSpan={HEADERS.length}>No questions found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
