import React from 'react';
const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {
	const handleClick = () => {
		setActiveTab(id);
	};

	return (
		<div onClick={handleClick} className={activeTab === id ? 'active' : ''}>
			{title}
		</div>
	);
};
export default TabNavItem;
